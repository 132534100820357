import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import axios from "./shared/service/axios_global/axi_gbl";
import { addUdata } from "./shared/redux/actions";
import { encrypt } from "./shared/service/crypto";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"> </div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {
  const _isMounted = useRef(false);

  //@STATE
  const [isLoading, setIsLoading] = useState(true);

  //@ REDUX
  // ACTION
  const dispatch = useDispatch();
  const _AddUserInfo = info => dispatch(addUdata(info));
  // GET STATE FROM REDUX
  const user_info = useSelector(state => state.userInfoStore.user_info);

  /** || --==-- || EDIT BY LEMON || --==-- ||
  |-----------------------------------------------------------------------------------------------
  | |==| COMPONENT |==|
  |-----------------------------------------------------------------------------------------------
  */
  useEffect(() => {
    _isMounted.current = true;

    isLoginAdmin();
    return () => {
      _isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** || --==-- || EDIT BY LEMON || --==-- ||
  |-----------------------------------------------------------------------------------------------
  | |==| FETCH FUNCTION |==|
  |-----------------------------------------------------------------------------------------------
  */
  function isLoginAdmin() {
    axios({ method: "POST", url: "auths/isLoginAdmin" })
      .then(response => {
        // console.log(response);
        const data = response.data.response_data;

        _AddUserInfo(data);
        _isMounted.current && setIsLoading(currState => !currState);

        const encrypt_userinfo = encrypt(JSON.stringify(data));
        localStorage.setItem("User_Info", encrypt_userinfo);
      })
      .catch(error => {
        // console.log(error.response);

        _isMounted.current && setIsLoading(currState => !currState);
      });
  }

  /** || --==-- || EDIT BY LEMON || --==-- ||
  |-----------------------------------------------------------------------------------------------
  | |==| AUTH |==|
  |-----------------------------------------------------------------------------------------------
  */
  const RequireAuth = ({ children }) => {
    if (!user_info) return <Redirect to="/login" />;

    return children;
  };

  /** || --==-- || EDIT BY LEMON || --==-- ||
  |-----------------------------------------------------------------------------------------------
  | |==| RENDER |==|
  |-----------------------------------------------------------------------------------------------
  */

  return isLoading ? (
    <div className="wrap_loading">
      <div className="loading">
        <div className="bounceball_loading"></div>
        <div className="text_loading">NOW LOADING</div>
      </div>
    </div>
  ) : (
    <React.Suspense fallback={loading}>
      <Switch>
        <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
        <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
        <RequireAuth>
          <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
        </RequireAuth>
      </Switch>
    </React.Suspense>
  );
};

export default App;
